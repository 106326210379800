import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Otherservice from "../components/otherservice";
import { IoArrowUpOutline } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import { variantPages } from "../animations/pageTransition";
import Form from "../components/form";
import MetaTag from "../components/metaTag";
import { subscriptionSEO } from "../components/data/seo";
import {
  containercertandsubs,
  preview,
  stock,
  option,
  price,
  list,
  link,
  background,
  title,
  description,
  vector,
  otherservices,
  btn,
} from "../styles/pages/service.module.scss";
import "swiper/css";
import "swiper/css/pagination";

function Subscription() {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <MetaTag data={subscriptionSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
      >
        <div className={containercertandsubs}>
          <div className={preview}>
            <h2 className={title}>Абонементы</h2>
            <p className={description}>
              4 уборки – 8000₽ до 90 квадратов!
              <br />
              8 уборок – 14500₽ до 90 квадратов!
              <br />
              От 90 квадратов по договоренности
            </p>
            <StaticImage
              src="../images/services/10.jpeg"
              alt="обложка для абонементов клинтим"
              className={background}
            />
          </div>
          <div className={stock}>
            <h3 className={title}>
              Абонемент на
              <br /> ежедневную уборку
            </h3>
            <button className={btn} onClick={() => setOpenForm(true)}>
              Заказать абонемент <IoArrowUpOutline />
            </button>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </div>
          <div
            className={option}
            style={{
              background: "linear-gradient(180deg, #4449C5 0%, #FFA764 100%)",
            }}
          >
            <h2 className={title}>В ежедневную уборку входит:</h2>
            <p className={list}>
              - Обеспыливание поверхностей
              <br />
              - Обеспыливание подоконников
              <br />
              - Промывание радиаторов (парогенератором)
              <br />
              - Обеспыливание бытовой техники <br />
              - Сухая чистка диванов
              <br />
              - Уборка профессиональной химией санузлов, ванной (кафель,
              сантехника, зеркала, стиральная машинка)
              <br />
              - Кухня: мытье фасадов кухонного гарнитура снаружи, холодильник
              снаружи, (мытье посуды по договоренности)
              <br />
              - Замена постельного белья и загрузка в стиральную машинку (стирка
              по договоренности)
              <br />
              - Мытье полов в легкодоступных местах (тяжело выдвигаемую мебель
              не двигаем)
              <br />
              - Шкафы, кладовки мытье полов, открытых и свободных полочек
              <br />
              - Разобрать сушилку с высохшим бельем (глажка в условия договора
              не входит)
              <br />
              - Вынос мусора
              <br />
              <br />
              <span>
                Весь инвентарь, профессиональная химия, клинеры входят в условия
                договора!
              </span>
            </p>
            <a href="/doc/dogovor.pdf" target="_blank" className={link}>
              Скачать договор об оказании услуг
            </a>
          </div>
          <div
            className={price}
            style={{
              background: "linear-gradient(64.7deg, #D15842 0%, #F17F29 100%)",
            }}
          >
            <h2 className={title}>Условия использования:</h2>
            <p className={list}>
              Вся сумма за уборку оплачивается единоразово. <br />
              <span>
                Стоимость не привязана к месяцу, можно использовать когда
                удобно.{" "}
              </span>
              Через день, либо раз в месяц по договоренности. <br />
              Время и дата уборки оговаривается заранее. <br />
              Подстраиваемся под график клиентов.
              <br />
              Можно рассчитываться как наличными, так и переводом, как по
              терминалу, так и через расчетный счет.
              <br />
              <br />
              <span>
                Вы можете приобрести сертификат как себе так и близким на
                праздники.
              </span>{" "}
              Договор составляется на конкретный адрес, передаривать или
              передавать его нельзя.
            </p>
            <img src="/cartvector/1.svg" alt="фон вектор" className={vector} />
          </div>
        </div>
        <h3 className={otherservices}>Наши услуги:</h3>
        <Otherservice hide={false} />
        <AnimatePresence>
          {openForm && (
            <Form
              service="абонемент на уборку"
              closeForm={() => setOpenForm(false)}
            />
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
}

export default Subscription;
